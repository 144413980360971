<!--
  服务集成商认证（普通服务用户）
 -->
<template>
  <div class="detail page_min_width">
    <detail-header title="服务集成商认证（普通服务用户）" />
    <div class="detail_main page_width" v-loading="loading">
      <div class="main_content">
        <el-form
          ref="elForm"
          :model="form"
          :rules="rules"
          size="small"
          label-width="130px"
          label-position="left">
          <el-row type="flex" :gutter="70">
            <el-col>
              <el-form-item
                label="姓名">
                <el-input maxlength="50" show-word-limit
                  style="width: 100%;"
                  readonly
                  :value="userInfo.username"
                  placeholder="请输入姓名">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item
                label="最高学历">
                <el-input maxlength="50" show-word-limit
                  style="width: 100%;"
                  readonly
                  :value="userInfo.highestEducation"
                  placeholder="请输入姓名">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" :gutter="70">
            <el-col>
              <el-form-item
                label="毕业院校"
                prop="university">
                <el-input maxlength="50" show-word-limit
                  style="width: 100%;"
                  v-model="form.university"
                  placeholder="请输入毕业院校">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item
                label="毕业证书编号"
                prop="certificateNumber">
                <el-input maxlength="50" show-word-limit
                  style="width: 100%;"
                  v-model="form.certificateNumber"
                  placeholder="请输入毕业证书编号">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" :gutter="70">
            <el-col>
              <el-form-item
                label="社会职务"
                prop="socialPosition">
                <el-input maxlength="50" show-word-limit
                  style="width: 100%;"
                  v-model="form.socialPosition"
                  placeholder="请输入社会职务">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item
                label="从事科技服务年限"
                prop="workingYears">
                <el-input maxlength="50" show-word-limit
                  style="width: 100%;"
                  v-model.number="form.workingYears"
                  placeholder="请输入从事科技服务年限">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" :gutter="70">
            <el-col>
              <el-form-item
                label="资格证书"
                prop="qualification">
                <el-input maxlength="50" show-word-limit
                  style="width: 100%;"
                  v-model="form.qualification"
                  placeholder="请输入所获得资格证书">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item
                label="资格证书发证时间"
                prop="issuingAt">
                <el-date-picker
                  style="width: 100%;"
                  v-model="form.issuingAt"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="请选择证书发放时间">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item
            label="证书图片或扫描件"
            prop="certificateAttachment">
            <upload-files
              :maxSize="4"
              accept="image/png, image/jpg, image/jpeg"
              :showTip="false"
              listType="picture-card"
              :fileList.sync="form.certificateAttachment">
            </upload-files>
          </el-form-item>
          <el-form-item
            label="服务对象代表"
            prop="serviceObjectRepresentative">
            <el-input
              style="width: 100%;"
              type="textarea" maxlength="300" show-word-limit
              :rows="4"
              placeholder="请输入主要的服务对象单位名称3-5个"
              v-model="form.serviceObjectRepresentative">
            </el-input>
          </el-form-item>
          <el-form-item
            label="参与或主持的项目"
            prop="projectCases">
            <el-input
              style="width: 100%;"
              type="textarea" maxlength="300" show-word-limit
              :rows="4"
              placeholder="请输入具体主持或参与的项目名称，工作内容、本人起何作用，完成情况及效果"
              v-model="form.projectCases">
            </el-input>
          </el-form-item>
          <el-form-item
            label="成果案例"
            prop="achievementCases">
            <el-input
              style="width: 100%;"
              type="textarea" maxlength="300" show-word-limit
              :rows="4"
              placeholder="请输入主持或参与撰写的著作、论文、标准、研究（调研）报告、项目可行性分析报告、技术咨询报告等"
              v-model="form.achievementCases">
            </el-input>
          </el-form-item>
          <el-form-item
            label="获奖情况"
            prop="awardCases">
            <el-input
              style="width: 100%;"
              type="textarea" maxlength="300" show-word-limit
              :rows="4"
              placeholder="填写与科技服务业相关的奖励情况"
              v-model="form.awardCases">
            </el-input>
          </el-form-item>
          <el-form-item
            label="技术服务情况描述"
            prop="situationDescription">
            <el-input
              style="width: 100%;"
              type="textarea" maxlength="300" show-word-limit
              :rows="4"
              placeholder="请输入从事科技服务业以来服务企业数量，承办活动数量，促进科技成果转化项数，促成的技术交易额，促成企业融资金融等。"
              v-model="form.situationDescription">
            </el-input>
          </el-form-item>
          <el-form-item
            label="相关凭证"
            prop="relatedDocuments">
            <upload-files
              :maxSize="4"
              accept=""
              tips="与被服务方签订的服务项目协议及过程文档，转让或受让方与被服务方的技术合同、相关新闻报道等"
              :fileList.sync="form.relatedDocuments">
            </upload-files>
          </el-form-item>
        </el-form>

        <div class="btn_box tw-mt-30px">
          <el-button
            style="margin-right: 20px;"
            type="primary"
            size="small"
            @click="submitForm"
            :loading="btnLoading"
            :disabled="!agree">
            提交申请
          </el-button>
          <el-checkbox v-model="agree">
            <p class="tw-text-14px tw-text-primary-text">已仔细阅读并同意
              <a :href="`${publicPath}诚信承诺书.pdf`" target="_blank">《诚信承诺书》</a>
            </p>
          </el-checkbox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from '@/api'

export default {
  data () {
    return {
      // 页面loading
      loading: false,
      btnLoading: false,
      agree: false, // 是否同意条款
      /* form: {
        university: '越海棠高级相学学院',
        certificateNumber: '520922199710136815',
        socialPosition: '法师',
        workingYears: 10,
        qualification: 'SS级法师资格证',
        issuingAt: '2021-03-19',
        certificateAttachment: [
          {
            uid: 1615791612018,
            name: 'certificate.png',
            id: 'c76a17ec814ef9eee9a52485f0b48b97',
            createdAt: '2021-03-15 15:00:12',
            updatedAt: '2021-03-15 15:00:12',
            deletedAt: null,
            driver: 'local',
            originName: 'certificate.png',
            extName: 'png',
            contentType: 'image/png',
            size: '1509',
            path: '2021/3/15/c76a17ec814ef9eee9a52485f0b48b97.png',
            url: 'http://192.168.1.175:9919/media/c76a17ec814ef9eee9a52485f0b48b97',
            downloadUrl: 'http://192.168.1.175:9919/media/c76a17ec814ef9eee9a52485f0b48b97/download',
            status: 'success'
          }
        ],
        serviceObjectRepresentative: '1、狍子\n2、阿一',
        projectCases: '1、南海唤雨\n2、东海呼风',
        achievementCases: '1、《东海呼风术》\n2、《南海唤雨术》',
        awardCases: '1、国际相学大师奖',
        situationDescription: '1、承办500个企业',
        relatedDocuments: [
          {
            uid: 1615791804241,
            name: '641.webp',
            id: '889355e6c758b86279aae7510bfa2282',
            createdAt: '2021-03-15 15:03:24',
            updatedAt: '2021-03-15 15:03:24',
            deletedAt: null,
            driver: 'local',
            originName: '641.webp',
            extName: 'webp',
            contentType: 'image/webp',
            size: '25',
            path: '2021/3/15/889355e6c758b86279aae7510bfa2282.webp',
            url: 'http://192.168.1.175:9919/media/889355e6c758b86279aae7510bfa2282',
            downloadUrl: 'http://192.168.1.175:9919/media/889355e6c758b86279aae7510bfa2282/download',
            status: 'success'
          },
          {
            uid: 1615791810222,
            name: 'gaohu.jpg',
            id: '0bcda57cd8b88113abec574ad4746407',
            createdAt: '2021-03-15 15:03:30',
            updatedAt: '2021-03-15 15:03:30',
            deletedAt: null,
            driver: 'local',
            originName: 'gaohu.jpg',
            extName: 'jpg',
            contentType: 'image/jpeg',
            size: '46',
            path: '2021/3/15/0bcda57cd8b88113abec574ad4746407.jpg',
            url: 'http://192.168.1.175:9919/media/0bcda57cd8b88113abec574ad4746407',
            downloadUrl: 'http://192.168.1.175:9919/media/0bcda57cd8b88113abec574ad4746407/download',
            status: 'success'
          },
          {
            uid: 1615791821137,
            name: '测试doc-word.doc',
            id: '035de708489c588c051a11d6e3a35b82',
            createdAt: '2021-03-15 15:03:41',
            updatedAt: '2021-03-15 15:03:41',
            deletedAt: null,
            driver: 'local',
            originName: '测试doc-word.doc',
            extName: 'doc',
            contentType: 'application/msword',
            size: '747',
            path: '2021/3/15/035de708489c588c051a11d6e3a35b82.doc',
            url: 'http://192.168.1.175:9919/media/035de708489c588c051a11d6e3a35b82',
            downloadUrl: 'http://192.168.1.175:9919/media/035de708489c588c051a11d6e3a35b82/download',
            status: 'success'
          }
        ]
      }, */
      form: {
        university: '', // 毕业院校
        certificateNumber: '', // 毕业证书编号
        socialPosition: '', // 社会职务
        workingYears: '', // 从事科技服务年限
        qualification: '', // 资格证书
        issuingAt: '', // 资格证书发证时间
        certificateAttachment: [], // 证书图片或扫描件
        serviceObjectRepresentative: '', // 服务对象代表
        projectCases: '', // 参与或主持的项目
        achievementCases: '', // 成果案例
        awardCases: '', // 获奖情况
        situationDescription: '', // 技术服务情况描述
        relatedDocuments: [] // 相关凭证
      },
      rules: {
        university: [
          { required: true, message: '请输入毕业院校', trigger: 'blur' }
        ],
        certificateNumber: [
          { required: true, message: '请输入毕业证书编号', trigger: 'blur' }
        ],
        socialPosition: [
          { required: true, message: '请输入社会职务', trigger: 'blur' }
        ],
        workingYears: [
          { required: true, message: '请输入从事科技服务年限', trigger: 'blur' },
          { pattern: /^(([1-9][0-9]*)|[0])$/, message: '请输入整数', trigger: 'blur' }
        ],
        qualification: [
          { required: true, message: '请输入所获得资格证书', trigger: 'blur' }
        ],
        issuingAt: [
          { required: true, message: '请选择资格证书发证时间', trigger: 'change' }
        ],
        certificateAttachment: [
          { required: true, message: '请上传证书图片或扫描件', trigger: 'change' }
        ],
        serviceObjectRepresentative: [
          { required: true, message: '请输入主要的服务对象单位名称3-5个', trigger: 'blur' }
        ],
        projectCases: [
          { required: true, message: '请输入主持或参与的项目', trigger: 'blur' }
        ],
        achievementCases: [
          { required: true, message: '请输入成果案例', trigger: 'blur' }
        ],
        awardCases: [
          { required: true, message: '请输入获奖情况', trigger: 'blur' }
        ],
        situationDescription: [
          { required: true, message: '请输入技术服务情况描述', trigger: 'blur' }
        ],
        relatedDocuments: [
          { required: true, message: '请上传相关凭证', trigger: 'change' }
        ]
      }
    }
  },
  computed: {
    // 开放目录
    publicPath () {
      return process.env.BASE_URL
    }
  },
  methods: {
    // 表单验证
    submitForm () {
      this.$refs.elForm.validate((valid) => {
        if (valid) {
          // 申请服务机构
          this.orgPersonAggregatorRegister()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 服务商认证（个人）
    orgPersonAggregatorRegister () {
      let {
        certificateAttachment,
        relatedDocuments
      } = this.form

      certificateAttachment = certificateAttachment ? certificateAttachment.map(item => {
        return item.url
      }).join(',') : ''
      relatedDocuments = relatedDocuments ? JSON.stringify(relatedDocuments) : ''

      const formData = {
        ...this.form,
        certificateAttachment,
        relatedDocuments
      }

      this.btnLoading = true
      api.orgPersonAggregatorRegister({
        ...formData
      }).then(res => {
        if (res.data.code === 0) {
          this.$message.success('申请成功')
          this.$router.replace('/personal-info')
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.btnLoading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.detail {
  .detail_main {
    padding: 30px 0px;
    .main_content {
      width: 1100px;
      margin: 0 auto;
      .module_box {
        .module_box_header {
          display: flex;
          align-items: center;
          height: 40px;
          margin-bottom: 20px;
          &::before {
            content: "";
            width: 3px;
            height: 20px;
            background: #3473E6;
            border-radius: 1px;
            margin-right: 10px;
          }
          & > p {
            font-size: 16px;
            font-weight: bold;
            line-height: 22px;
            color: #31394C;
          }
        }
        .module_box_main {
          padding: 0 50px 0 20px;
        }
        &+.module_box {
          margin-top: 40px;
        }
      }
      .btn_box {
        display: flex;
        align-items: center;
        padding-left: 130px;

      }
      .logourl_upload {
        position: relative;
        overflow: hidden;
        transition: all 0.3s ease;
        .uploader-icon {
          width: 120px;
          height: 120px;
          background-color: #fbfdff;
          border: 1px dashed #c0ccda;
          border-radius: 6px;
          cursor: pointer;
          line-height: 120px;
          vertical-align: top;
          &:hover {
            border-color: $color-primary;
          }
          & i {
            font-size: 28px;
            color: #8c939d;
          }
        }
      }
      .codeurl_upload {
        position: relative;
        overflow: hidden;
        transition: all 0.3s ease;
        .uploader_box {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 350px;
          height: 220px;
          background-color: #fbfdff;
          border: 1px dashed #c0ccda;
          border-radius: 6px;
          cursor: pointer;
          line-height: 120px;
          vertical-align: top;
          &:hover {
            border-color: $color-primary;
          }
          & > i {
            font-size: 40px;
            color: #9AA0A9;
          }
          & > p {
            width: 200px;
            font-size: 14px;
            line-height: 20px;
            color: #909399;
            margin-top: 15px;
          }
        }
      }
      .checkbox_wrapper {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 450px;
      }
    }
  }
}
</style>
